import React, { createContext, useState, useEffect, useContext } from "react";
import { useUser } from '../context/UserContext';

const LocationContext = createContext();

export const useLocation = () => useContext(LocationContext);

export const LocationProvider = ({ children }) => {
  const { user } = useUser();
  const userId = user?.email; // Using user's email as userId

  const [locationData, setLocationData] = useState({});
  const [selectedSection, setSelectedSection] = useState("Well location data");
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [selectedReference, setSelectedReference] = useState(null);
  const [selectedFieldData, setSelectedFieldData] = useState(null);
  const [selectedFieldReference, setSelectedFieldReference] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tempFieldValue, setTempFieldValue] = useState(null);
  const [tempReference, setTempReference] = useState(null);
  const [well, setWell] = useState({ well_name: "", well_id: "" });
  const [wellId, setWellId] = useState("");
  const [wellLocData, setWellLocData] = useState(null);
  const [pdfs, setPdfs] = useState([]);

  // Fetch well location data
  useEffect(() => {
    if (well.well_id) {
      const fetchData = async () => {
        try {
          const response = await fetch(`/v1/well/get-well-location-data/${well.well_id}`, {
            method: "GET",
            credentials: 'include',
          });
          if (response.ok) {
            const data = await response.json();
            if (data && data.data && data.data.wellLocation) {
              setLocationData(data.data.wellLocation);
              setPdfs(data.data.pdfs);
              setWellId(data.data.wellId);
            }
          }
        } catch (err) {
          setLocationData({});
          setPdfs([]);
        }
      };
      fetchData();
    }
  }, [well]);

  // Update well location data and maintaining history
  useEffect(() => {
    if (wellLocData && wellLocData.newValue && selectedFieldReference) {
      const updatedFieldData = {
        ...locationData[selectedFieldReference],
        display: {
          ...locationData[selectedFieldReference].display,
          value: wellLocData.newValue,
          reference: wellLocData.newReference,
          user_id : userId
        },
        history: [
          { value: locationData[selectedFieldReference].display.value, reference: locationData[selectedFieldReference].display.reference, user_id : userId },
          ...locationData[selectedFieldReference].history,
        ].slice(0, 3), // Latest 3 history records
      };

      setLocationData(prevData => ({
        ...prevData,
        [selectedFieldReference]: updatedFieldData,
      }));

      const updateDataOnServer = async () => {
        try {
          const response = await fetch(`/v1/well/update-well-location-data`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              well_id: wellId,
              well_location_data: {
                [selectedFieldReference]: updatedFieldData,
              }
            }),
            credentials: 'include'
          });

          if (response.ok) {
            const data = await response.json();
            console.log('Update successful:', data);
          }
        } catch (err) {
          console.error('Error updating well location data:', err);
        }
      };
      updateDataOnServer();
    }
  }, [wellLocData]);

  // Get well ID from query string
  const getWellIdFromQueryString = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('well_id');
  };

  useEffect(() => {
    const well_id = getWellIdFromQueryString();
    if (well_id) {
      setWell({ ...well, well_id });
      setWellId(well_id);
    }
  }, [window.location.search]);

  const updateWellLocation = (newData) => {
    setWellLocData(newData);
  };

  return (
    <LocationContext.Provider
      value={{
        well,
        setWell,
        locationData,
        setLocationData,
        selectedSection,
        setSelectedSection,
        isSlideOverOpen,
        setIsSlideOverOpen,
        selectedReference,
        setSelectedReference,
        selectedFieldData,
        setSelectedFieldData,
        selectedFieldReference,
        setSelectedFieldReference,
        isModalOpen,
        setIsModalOpen,
        tempFieldValue,
        setTempFieldValue,
        tempReference,
        setTempReference,
        setWellLocData, 
        updateWellLocation,
        pdfs,
        setWellId
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

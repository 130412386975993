import React, { useState, useEffect } from "react";
import { FaTrash, FaUndo } from "react-icons/fa";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useTrajectoryData } from "../../context/TrajectoryDataContext";
import AddNewTrajectoryEntrySlideOver from "./AddNewTrajectoryEntrySlideOver";
import { useLocation } from "../../context/LocationContext";
import { useImageViewer } from "../../context/ImageViewerContext";
import { useUser } from '../../context/UserContext'; 

const TableHeader = () => (
  <tr>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      MD
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Inclination
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Azimuth
    </th>
  </tr>
);

const TrajectoryData = () => {
  const { user } = useUser();
  const { data, updateData, handleAddNewEntry, handleDelete, handleRestore } = useTrajectoryData();

  const [isAddNewEntrySlideOverOpen, setIsAddNewEntrySlideOverOpen] = useState(false);
  const [localAcceptedValues, setLocalAcceptedValues] = useState([]);
  const [localDeletedValues, setLocalDeletedValues] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const { setSelectedReference, setSelectedFieldData, setIsModalOpen, setTempFieldValue } = useLocation();
  const [selectedMD, setSelectedMD] = useState("");
  const { setIsSlideOverOpen, tempFieldValue, tempReference, newData } = useImageViewer();
  
  const [selectedFieldReference, setSelectedFieldReference] = useState(null); // Ensure this is defined

  useEffect(() => {
    setLocalAcceptedValues(Object.keys(data.acceptedValues || {}).map((val) => val));
    setLocalDeletedValues(Object.keys(data.deletedValues || {}).map((val) => val));
  }, [data]);

  useEffect(() => {
    handleSave(tempFieldValue, tempReference);
  }, [newData]);

  const handleSave = async (newValue, newReference) => {
    if (!newValue) return; 
    setTempFieldValue(newValue);
    setSelectedReference(newReference);
    console.log("Handle Save:", newValue, newReference);

    const tempData = data.acceptedValues;
    const currentDisplayValue = tempData[selectedMD]?.[selectedFieldReference]?.display.value || null;

    const newEntry = {
      [selectedMD]: {
        ...tempData[selectedMD],
        [selectedFieldReference]: {
          ...tempData?.[selectedMD]?.[selectedFieldReference],
          history: [
            {
              value: currentDisplayValue,
              reference: tempData[selectedMD]?.[selectedFieldReference]?.display.reference || null,
              user_id: tempData[selectedMD]?.[selectedFieldReference]?.display.user_id || user.email,
            },
            ...(tempData?.[selectedMD]?.[selectedFieldReference]?.history || []),
          ].slice(0, 2), // Keep only the last 2 entries
          display: {
            value: newValue,
            reference: newReference,
            user_id: user.email,
          },
        },
      },
    };

    console.log("After New Entry:", newEntry);
    const updatedData = {
      ...data,
      acceptedValues: {
        ...data.acceptedValues,
        ...newEntry,
      },
    };

    await updateData(updatedData);

    setSelectedReference(null);
    setSelectedFieldData(null);
    setSelectedFieldReference(null);
    setIsModalOpen(true);
  };

  const handleFieldClick = (value, fieldData, reference, displayValue) => {
    console.log("HandleFieldData:", value, fieldData, reference, displayValue);
    setSelectedMD(value);
    setSelectedFieldData(fieldData);
    setSelectedFieldReference(reference); // Set this correctly
    setSelectedReference(displayValue);
    setSelectedField(fieldData);
    setIsSlideOverOpen(true);
  };

  const renderTableRows = (values, isDeleted) => {
    if (!values) return null;
    return values.map((value) => {
      const fieldData = (data.acceptedValues?.[value]) || (data.deletedValues?.[value]);
      if (!fieldData) return null;

      return (
        <tr key={value}>
          <td className="px-4 py-2">{value}</td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              handleFieldClick(
                value,
                fieldData.inclination,
                "inclination",
                fieldData.inclination?.display?.value
              )
            }
          >
            {fieldData.inclination?.display?.value || "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              handleFieldClick(
                value,
                fieldData.azimuth,
                "azimuth",
                fieldData.azimuth?.display?.value
              )
            }
          >
            {fieldData.azimuth?.display?.value || "N/A"}
          </td>
          <td className="px-4 py-2 text-center flex flex-row justify-between items-center">
            <span className="flex-grow">
              {!isDeleted && (
                <button
                  onClick={(e) => { e.stopPropagation(); handleDelete(value); }}
                  className="text-red-500"
                >
                  <FaTrash />
                </button>
              )}
              {isDeleted && (
                <button
                  onClick={(e) => { e.stopPropagation(); handleRestore(value); }}
                  className="text-green-500"
                >
                  <FaUndo />
                </button>
              )}
            </span>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="flex flex-col justify-between">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-bold mb-4">Accepted Values</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(localAcceptedValues, false)}
          </tbody>
        </table>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center mt-2"
          onClick={() => setIsAddNewEntrySlideOverOpen(true)}
        >
          <PlusIcon className="w-4 h-4" />
        </button>
      </div>

      <div className="overflow-x-auto my-20">
        <h2 className="text-lg font-bold mb-4 text-gray-400">Deleted Values</h2>
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(localDeletedValues, true)}
          </tbody>
        </table>
      </div>

      <AddNewTrajectoryEntrySlideOver
        isOpen={isAddNewEntrySlideOverOpen}
        onClose={() => setIsAddNewEntrySlideOverOpen(false)}
        onSubmit={handleAddNewEntry}
        data={data}
      />
    </div>
  );
};

export default TrajectoryData;

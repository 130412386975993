import React, { useState, useEffect } from "react";
import { FaTrash, FaUndo } from "react-icons/fa";
import { PlusIcon } from "@heroicons/react/24/solid";
import ConfirmationModal from "../ConfirmationModal2";
import { useData } from "../../context/DataContext";
import AddNewEntrySlideOver from "./AddNewEntrySlideOver";
import { useLocation } from "../../context/LocationContext";
import { useImageViewer } from "../../context/ImageViewerContext";
import { Tooltip as ReactTooltip } from "react-tooltip";
const fields = [
  "casing_size",
  "casing_type",
  "casing_grade",
  "casing_start_md",
  "casing_end_md",
  "casing_weight",
  "hole_end_md",
  "hole_size",
  "toc_md"
]

const TableHeader = () => (
  <tr>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Casing Type
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Hole Size
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Hole End Md
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Casing Size
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Casing Grade
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Casing Weight
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Casing Start Md
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Casing End Md
    </th>
    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      Toc Md
    </th>
  </tr>
);

const HoleCasingData = () => {
  const { data, updateData, handleSaveFromImageViewer } = useData();
  const {
    setSelectedFieldData,
    setSelectedFieldReference,
    setSelectedReference,
    well,
    setIsModalOpen,
    setTempFieldValue,
  } = useLocation();

  const { setIsSlideOverOpen , newData} = useImageViewer();

  const [isAddNewEntrySlideOverOpen, setIsAddNewEntrySlideOverOpen] =
    useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [localAcceptedValues, setLocalAcceptedValues] = useState([]);
  const [localSuggestedValues, setLocalSuggestedValues] = useState([]);
  const [localDeletedValues, setLocalDeletedValues] = useState([]);
  const [suggestedValueToMove, setSuggestedValueToMove] = useState(null);
  const [selectedField, setSelectedField] = useState(null); // Store selected field data

  useEffect(() => {
    setLocalAcceptedValues(
      Object.keys(data.acceptedValues || {}).map((val) => (val))
    );
    setLocalSuggestedValues(
      Object.keys(data.suggestedValues || {}).map((val) => (val))
    );
    setLocalDeletedValues(
      Object.keys(data.deletedValues || {}).map((val) => (val)) 
    );
  }, [data]);

  useState(()=>{
    console.log("data",data);
    console.log("newdata:",newData);
    return;
    const updatedData = {
      ...data,
      acceptedValues: {...data.acceptedValues, [newData.value] : {...data.deletedValues[newData.value],restoredBy : 'UserId'}},
    }
    updateData(updatedData);
  },[newData]);

  const handleDelete = (value) => {
    const updatedAcceptedValues = localAcceptedValues.filter(
      (val) => val !== value
    );
    setLocalAcceptedValues(updatedAcceptedValues);
    setLocalDeletedValues([...localDeletedValues,value]);

    console.log("Object deleted",{...data.deletedValues, [value] : {...data.acceptedValues[value],deletedBy : 'UserId'}});
    const updatedData = {
      ...data,
      deletedValues: {...data.deletedValues, [value] : {...data.acceptedValues[value],deletedBy : 'UserId'}},
    };

    delete updatedData.acceptedValues[value];
    
    updateData(updatedData);
  };


  const handleRestore = (value) => {
    const updatedDeletedValues = localDeletedValues.filter(
      (val) => val !== value
    );
    setLocalDeletedValues(updatedDeletedValues);
    setLocalAcceptedValues([...localAcceptedValues, value]);

    console.log("Object restore",{...data.deletedValues, [value] : {...data.acceptedValues[value],deletedBy : 'UserId'}});
    const updatedData = {
      ...data,
      acceptedValues: {...data.acceptedValues, [value] : {...data.deletedValues[value],restoredBy : 'UserId'}},
    };

    delete updatedData.deletedValues[value];
    updateData(updatedData);
  };

  const handleMoveSuggestedValue = (value) => {
    setIsConfirmationModalOpen(true);
    setSuggestedValueToMove(value);
  };

  const confirmMoveSuggestedValue = () => {
    if (suggestedValueToMove !== null) {
      setLocalAcceptedValues([...localAcceptedValues, suggestedValueToMove]);
      const updatedSuggestedValues = localSuggestedValues.filter(
        (val) => val !== suggestedValueToMove
      );
      setLocalSuggestedValues(updatedSuggestedValues);

      const updatedData = {
        ...data,
        acceptedValues: [
          ...data.acceptedValues,
          { [suggestedValueToMove]: "userId", message: "restored BY" },
        ],
        suggestedValues: Object.keys(data.suggestedValues).reduce(
          (acc, key) => {
            if ((key) !== suggestedValueToMove) {
              acc[key] = data.suggestedValues[key];
            }
            return acc;
          },
          {}
        ),
      };

      updateData(updatedData);
      setIsConfirmationModalOpen(false);
      setSuggestedValueToMove(null);
    }
  };

  const handleAddNewEntry = (formData) => {
    console.log("formData",formData,data);
    if(!formData.casing_size) return;
    
    const newEntry = fields.reduce((acc, field) => {
      acc[formData.casing_size] = {
        ...acc[formData.casing_size],
        [field]: {
          options: {},
          history: [],
          display: {
            value: formData[field],
            reference: { pdf_id: "", page_number: formData[`${field}_ref`] || "" },
            user_id: ""
          }
        }
      };
      return acc;
    }, {});

    console.log("newEntry",newEntry);

    console.log("data:",data);
    const updatedData = {
      ...data,
      data: {
        ...data.data,
        ...newEntry,
      },
      acceptedValues: [
        ...data.acceptedValues,
        { [formData.casing_size]: "userId", message: "added BY" },
      ],
    };

    updateData(updatedData);
  };

  const handleFieldClick = (fieldData, reference, displayValue) => {
    setSelectedFieldData(fieldData);
    setSelectedFieldReference(reference);
    setSelectedReference(displayValue);
    setSelectedField(fieldData);
    setIsSlideOverOpen(true);
  };

  const renderTableRows = (data, values, isSuggested, isDeleted) => {
    if(!data) return null;
    if (!values) return null;
    return values.map((value) => {
      const fieldData = data[value];
      if (!fieldData) return null;

      return (
        <tr
          key={value}
          onClick={() => (isSuggested ? handleMoveSuggestedValue(value) : null)}
        >
          <td
            className="px-4 py-2"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                fieldData.casing_type,
                "Casing Type",
                fieldData.casing_type.display.value
              )
            }
          >
            {fieldData.casing_type
              ? fieldData.casing_type.display.value
              : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                fieldData.hole_size,
                "Hole Size",
                fieldData.hole_size.display.value
              )
            }
          >
            {fieldData.hole_size ? fieldData.hole_size.display.value : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                fieldData.hole_end_md,
                "Hole End Md",
                fieldData.hole_end_md.display.value
              )
            }
          >
            {fieldData.hole_end_md
              ? fieldData.hole_end_md.display.value
              : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested && handleFieldClick(fieldData, "Casing Size", value)
            }
          >
            {value ? value : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                fieldData.casing_grade,
                "Casing Grade",
                fieldData.casing_grade.display.value
              )
            }
          >
            {fieldData.casing_grade
              ? fieldData.casing_grade.display.value
              : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                fieldData.casing_weight,
                "Casing Weight",
                fieldData.casing_weight.display.value
              )
            }
          >
            {fieldData.casing_weight
              ? fieldData.casing_weight.display.value
              : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                fieldData.casing_start_md,
                "Casing Start Md",
                fieldData.casing_start_md.display.value
              )
            }
          >
            {fieldData.casing_start_md
              ? fieldData.casing_start_md.display.value
              : "N/A"}
          </td>
          <td
            className="px-4 py-2 text-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                fieldData.casing_end_md,
                "Casing End Md",
                fieldData.casing_end_md.display.value
              )
            }
          >
            {fieldData.casing_end_md
              ? fieldData.casing_end_md.display.value
              : "N/A"}
          </td>
          <td
            className="p-4 text-center flex flex-row justify-between items-center"
            onClick={() =>
              !isSuggested &&
              handleFieldClick(
                fieldData.toc_md,
                "Toc Md",
                fieldData.toc_md.display.value
              )
            }
          >
            <span className="text-center">
              {fieldData.toc_md ? fieldData.toc_md.display.value : "N/A"}
            </span>
            <div className="ml-2">
              {!isSuggested && !isDeleted && (
                <>
                  <button
                    data-tooltip-id="trashTooltip"
                    data-tooltip-content="Delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(value);
                    }}
                    className="text-red-500"
                  >
                    <FaTrash />
                  </button>
                  <ReactTooltip id="trashTooltip" />
                </>
              )}
              {isDeleted && (
                <>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRestore(value);
                    }}
                    className="text-green-500"
                    data-tooltip-id="restoreTooltip"
                    data-tooltip-content="Restore"
                  >
                    <FaUndo />
                  </button>
                  <ReactTooltip id="restoreTooltip" />
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="flex flex-col justify-between">
      <div className="flex items-center justify-between">
        <h2 className="text-lg font-bold mb-4">Accepted Values</h2>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(data['acceptedValues'],localAcceptedValues, false, false)}
          </tbody>
        </table>
        <>
          <button
            data-tooltip-id="plusToolTip"
            data-tooltip-content="Add New Data"
            className="bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center mt-2"
            onClick={() => setIsAddNewEntrySlideOverOpen(true)}
          >
            <PlusIcon className="w-4 h-4" />
          </button>
          <ReactTooltip id="plusToolTip" />
        </>
      </div>

      <div className="overflow-x-auto mt-20">
        <h2 className="text-lg font-bold mb-4 text-gray-400">
          Suggested Values
        </h2>
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(data['suggestedValues'],localSuggestedValues, true, false)}
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto my-20">
        <h2 className="text-lg font-bold mb-4 text-gray-400">Deleted Values</h2>
        <table className="min-w-full bg-white border border-gray-200 divide-y divide-gray-200">
          <thead>
            <TableHeader />
          </thead>
          <tbody className="divide-y divide-gray-200">
            {renderTableRows(data['deletedValues'],localDeletedValues, false, true)}
          </tbody>
        </table>
      </div>

      <AddNewEntrySlideOver
        isOpen={isAddNewEntrySlideOverOpen}
        onClose={() => setIsAddNewEntrySlideOverOpen(false)}
        onSubmit={handleAddNewEntry}
        data={data}
      />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={confirmMoveSuggestedValue}
        value={suggestedValueToMove}
      />
    </div>
  );
};

export default HoleCasingData;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logout, isAuthenticated } from "../config/auth";
import Sidebar from "../components/Sidebar";
import WellLocationData from "../components/WellLocation/WellLocationData";
import HoleCasingData from "../components/HoleCasing/HoleCasingData";
import { useLocation } from "../context/LocationContext";
import { useData } from "../context/DataContext";
import { useData as useFormation } from "../context/FormationDataContext";
import { useComplicationData as useComplication } from "../context/ComplicationDataContext";
import { useTrajectoryData as useTrajectory } from "../context/TrajectoryDataContext";
import FormationData from "../components/FormationData/FormationData";
import ComplicationData from "../components/ComplicationData/ComplicationData";
import TrajectoryData from "../components/TrajectoryData/TrajectoryData";

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedCompany] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedField, setSelectedField] = useState("");
  const [selectedSite, setSelectedSite] = useState("");
  const [selectedWell, setSelectedWell] = useState({ well_name : "",well_id : ""});
  const [activeMenu, setActiveMenu] = useState("");
  const [countries, setCountries] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [fields, setFields] = useState([]);
  const [sites, setSites] = useState([]);
  const [wells, setWells] = useState([]);
  const navigate = useNavigate();

  const { setWell, selectedSection, setSelectedSection } = useLocation();

  const { setWellId : setFormationWellId } = useFormation();
  const { setWellId : setComplicationWellId } = useComplication();
  const { setWellId : setTrajectoryWellId } = useTrajectory();
  const { setWellId } = useData();

  useEffect(() => {
    isAuthenticated().then((auth) => {
      if (!auth) {
        navigate("/login");
      }
    });
  }, [navigate]);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleButtonClick = (section) => {
    setSelectedSection(section);
  };

  useEffect(() => {
    setWell(selectedWell);
    setFormationWellId(selectedWell?.well_id);
    setComplicationWellId(selectedWell?.well_id);
    setTrajectoryWellId(selectedWell?.well_id);
    setWellId(selectedWell?.well_id);

  }, [selectedWell]);

  const renderSelectedSection = () => {
    if (selectedSection === "Well location data") {
      return <WellLocationData />;
    } else if (selectedSection === "Hole-casing data") {
      return <HoleCasingData />;
    } else if (selectedSection === "Formation data") {
      return <FormationData />;
    } else if(selectedSection === "Complication data") {
      return <ComplicationData />;
    } else if(selectedSection === "Trajectory data") {
      return <TrajectoryData />;
    }
   };

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex flex-1 overflow-hidden">
        <Sidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          selectedBlock={selectedBlock}
          setSelectedBlock={setSelectedBlock}
          selectedField={selectedField}
          setSelectedField={setSelectedField}
          selectedSite={selectedSite}
          setSelectedSite={setSelectedSite}
          selectedWell={selectedWell}
          setSelectedWell={setSelectedWell}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          countries={countries}
          setCountries={setCountries}
          blocks={blocks}
          setBlocks={setBlocks}
          fields={fields}
          setFields={setFields}
          sites={sites}
          setSites={setSites}
          wells={wells}
          setWells={setWells}
          handleButtonClick={handleButtonClick}
        />
        <main className="flex-1 p-8 bg-gray-100 flex flex-col overflow-y-auto">
          <img src="/assets/images/logo.png" alt="Data Drill" className={"transition-all duration-300 w-1/4"} />
          {selectedWell && (
            <>
              <div className="flex justify-left mt-10 space-x-2 pb-10 border-b border-black">
                <button
                  onClick={() => handleButtonClick("Well location data")}
                  className={`p-2 rounded border ${
                    selectedSection === "Well location data" ? "bg-indigo-700 text-white" : "hover:bg-gray-300 text-black"
                  }`}
                >
                  Well location data
                </button>
                <button
                  onClick={() => handleButtonClick("Hole-casing data")}
                  className={`p-2 rounded border ${
                    selectedSection === "Hole-casing data" ? "bg-indigo-700 text-white" : "hover:bg-gray-300 text-black"
                  }`}
                >
                  Hole-casing data
                </button>
                <button
                  onClick={() => handleButtonClick("Trajectory data")}
                  className={`p-2 rounded border ${
                    selectedSection === "Trajectory data" ? "bg-indigo-700 text-white" : "hover:bg-gray-300 text-black"
                  }`}
                >
                  Trajectory data
                </button>
                <button
                  onClick={() => handleButtonClick("Formation data")}
                  className={`p-2 rounded border ${
                    selectedSection === "Formation data" ? "bg-indigo-700 text-white" : "hover:bg-gray-300 text-black"
                  }`}
                >
                  Formation data
                </button>
                <button
                  onClick={() => handleButtonClick("Complication data")}
                  className={`p-2 rounded border ${
                    selectedSection === "Complication data" ? "bg-indigo-700 text-white" : "hover:bg-gray-300 text-black"
                  }`}
                >
                  Complication data
                </button>
              </div>
              <div className="my-10">
                <h1 className="text-2xl font-bold mb-4">{selectedSection}</h1>
                <div className="overflow-x-auto">{renderSelectedSection()}</div>
              </div>
            </>
          )}
          <footer className="mt-auto py-4 mx-20 flex justify-between">
            <p className="text-center text-gray-600">Privacy Policy</p>
            <p className="text-center text-gray-600">© [Datadrill] [2024]. All rights reserved.</p>
          </footer>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { createContext, useContext, useState, useEffect } from 'react';
import { isAuthenticated, login, logout } from '../config/auth';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const auth = await isAuthenticated();
      if (auth) {
        setUser({email : auth});
      }
    };
    checkAuth();
  }, []);

  const handleLogin = async (email, password) => {
    const userData = await login(email, password);
    if(userData && userData.success) {
      const auth = await isAuthenticated();
      if (auth) {
        setUser({email : auth});
      } 
    }
  };

  const handleLogout = () => {
    logout(); 
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, handleLogin, handleLogout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};

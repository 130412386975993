import React, { createContext, useContext, useState } from "react";
import ImageViewer from "../components/ImageViewer";

const ImageViewerContext = createContext();

export const useImageViewer = () => useContext(ImageViewerContext);

export const ImageViewerProvider = ({ children }) => {
  const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
  const [tempFieldValue, setTempFieldValue] = useState("");
  const [tempReference, setTempReference] = useState({page_number : '',pdf_id : ''});
  const [newData, onSave] = useState("");

  const handleSaveFromImageViewer = (newValue, newReference) => {
    setTempFieldValue(newValue);
    setTempReference(newReference);
    onSave({
      newValue,
      newReference
    });
    setIsSlideOverOpen(false);
  };

  return (
    <ImageViewerContext.Provider
      value={{
        isSlideOverOpen,
        setIsSlideOverOpen,
        handleSaveFromImageViewer,
        tempFieldValue,
        tempReference,
        newData
      }}
    >
      {children}
      <ImageViewer
        isSlideOverOpen={isSlideOverOpen}
        setIsSlideOverOpen={setIsSlideOverOpen}
        onSave={handleSaveFromImageViewer}
      />
    </ImageViewerContext.Provider>
  );
};
